@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Volkhov:ital@0;1&display=swap');

.fade-appear {
  opacity: 0;
  z-index: 1;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1500ms linear;
}

h1 {
  color: white;
  font-size: 60px;
  height: 1rem;
  white-space: nowrap;
  font-weight: 00;
  letter-spacing: 2.5px;
  font-family: 'Volkhov', serif;
}

.sub-text {
  height: 10rem;
  border: 1px #282c34 solid;
}

.sub-text > div > h4 {
  font-family: 'Volkhov', serif;
  color: white;
  margin: 2rem 0;
  font-weight: 600;
}

.card-image img {
  height: 300px;
}

.card {
  width: 300px;

  background-color: #282c34 !important;
}

.card-content p {
  font-family: 'Playfair Display', serif;

  letter-spacing: 1px;
}

.card-wrapper {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 1340px) {
  .card-wrapper {
    margin-top: 11rem;
    display: flex;
    justify-content: flex-start;
  }

  .modals {
    margin-top: 40rem;
  }

  h1 {
    white-space: normal;
  }

  .title {
    margin: 4rem 0;
  }
}

@media only screen and (max-width: 440px) {
  .layout > div h1 {
    font-size: 40px;
  }
}
