.page {
  position: absolute;
  left: 0;
  right: 0;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease 300ms;
}
