@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Volkhov:ital@0;1&display=swap');
nav {
  background-color: #282c34 !important ;
  height: 90px !important;

  padding: 0;
}

.logo {
  height: 62px;
  width: 60px;
  border: white 2px solid;
  background-color: white;
  border-radius: 50%;
  margin: 0rem 0 0 5rem;
  padding: 12px 0 0 3px;
  color: #282c34;
  font-family: 'Volkhov', serif;
  font-size: 28px;
}

.nav ul {
  margin-right: 19vh;
  margin-top: 2vh;
  display: flex;
  font-family: 'Volkhov', serif;
}

strong {
  font-size: 25px;
}

@media only screen and (max-width: 750px) {
  .nav ul > a {
    padding: 2px;
  }

  .nav ul {
    white-space: nowrap;
    margin-right: 0px;
  }

  .logo-wrapper {
    position: absolute;
    left: 0px;
    top: 20px;
  }
}
