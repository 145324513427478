@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&family=Volkhov:ital@0;1&display=swap');

.button {
  color: white !important;
}

.modals:hover > .country-happiness-frontend {
  opacity: 0.5;
}

.modals:hover > .country-happiness-frontend:hover {
  opacity: 1;
}

.modals:hover > .yakyik {
  opacity: 0.5;
}

.modals:hover > .yakyik:hover {
  opacity: 1;
}

.modals:hover > .movie-tv-finder {
  opacity: 0.5;
}

.modals:hover > .movie-tv-finder:hover {
  opacity: 1;
}

.modals:hover > .cotripper {
  opacity: 0.5;
}

.modals:hover > .cotripper:hover {
  opacity: 1;
}

.modals {
  width: 16rem;
  margin-top: 8rem;
  font-weight: 400;

  font-family: 'Volkhov', serif;
}

.modals > div > a {
  font-size: 35px;
  margin: 0rem 9rem 1.5rem 0;
  white-space: nowrap;
  padding-bottom: 70px;
  height: 20px;
}

.tooltip-img {
  width: 700px;
  opacity: 0.9;
}

blockquote {
  padding-left: 1.5 rem;
  height: 35px;
  border-left: 4px solid white !important;
  color: white;
}

@media only screen and (max-width: 686px) {
  .modals > div > a {
    margin: 6rem 9rem 1.5rem 0;
    white-space: normal;
  }
}
