@import url(https://fonts.googleapis.com/css2?family=Reem+Kufi&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Volkhov:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cutive+Mono&family=Volkhov:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Volkhov:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Volkhov:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Volkhov:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Volkhov:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Volkhov:ital@0;1&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-repeat: no-repeat;
}

h1 h2 h3 h4 {
  font-family: 'Reem Kufi', sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  background: linear-gradient(350deg, white 25%, #282c34 0%);
  box-sizing: border-box;
  height: 170vh;
  width: 100%;
}

.nav {
  background-color: white;
}

.main {
  background: local white;
}

.footer {
  background: linear-gradient(350deg, #282c34 81%, white 0%);
  height: 100%;
}

.material-icons.a {
  border: solid black 2px;
  border-radius: 50%;
  padding: 12px;
  height: 70px;
  font-size: 40px;
  color: white;
  background-color: #282c34;
  margin-right: 25px;
}

.material-icons.b {
  border: solid white 2px;

  border-radius: 50%;
  padding: 12px;
  height: 70px;
  font-size: 40px;
  color: white;
}

.icon {
  margin-left: 1rem;
}

@media only screen and (max-width: 1340px) {
  .header {
    height: 270vh;
  }
}

@media only screen and (max-width: 400px) {
  .header {
    height: 370vh;
  }
}

nav {
  background-color: #282c34 !important ;
  height: 90px !important;

  padding: 0;
}

.logo {
  height: 62px;
  width: 60px;
  border: white 2px solid;
  background-color: white;
  border-radius: 50%;
  margin: 0rem 0 0 5rem;
  padding: 12px 0 0 3px;
  color: #282c34;
  font-family: 'Volkhov', serif;
  font-size: 28px;
}

.nav ul {
  margin-right: 19vh;
  margin-top: 2vh;
  display: flex;
  font-family: 'Volkhov', serif;
}

strong {
  font-size: 25px;
}

@media only screen and (max-width: 750px) {
  .nav ul > a {
    padding: 2px;
  }

  .nav ul {
    white-space: nowrap;
    margin-right: 0px;
  }

  .logo-wrapper {
    position: absolute;
    left: 0px;
    top: 20px;
  }
}

.button {
  color: white !important;
}

.modals:hover > .country-happiness-frontend {
  opacity: 0.5;
}

.modals:hover > .country-happiness-frontend:hover {
  opacity: 1;
}

.modals:hover > .yakyik {
  opacity: 0.5;
}

.modals:hover > .yakyik:hover {
  opacity: 1;
}

.modals:hover > .movie-tv-finder {
  opacity: 0.5;
}

.modals:hover > .movie-tv-finder:hover {
  opacity: 1;
}

.modals:hover > .cotripper {
  opacity: 0.5;
}

.modals:hover > .cotripper:hover {
  opacity: 1;
}

.modals {
  width: 16rem;
  margin-top: 8rem;
  font-weight: 400;

  font-family: 'Volkhov', serif;
}

.modals > div > a {
  font-size: 35px;
  margin: 0rem 9rem 1.5rem 0;
  white-space: nowrap;
  padding-bottom: 70px;
  height: 20px;
}

.tooltip-img {
  width: 700px;
  opacity: 0.9;
}

blockquote {
  padding-left: 1.5 rem;
  height: 35px;
  border-left: 4px solid white !important;
  color: white;
}

@media only screen and (max-width: 686px) {
  .modals > div > a {
    margin: 6rem 9rem 1.5rem 0;
    white-space: normal;
  }
}

.about > h1 {
  color: black;
  margin-bottom: 7rem;
}

.about {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  align-items: center;
}

.about-contents {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
  width: 100vh;
}

span {
  font-size: 22px;
  font-weight: 800;
}

p.flow-text {
  font-size: 20px;
  font-family: 'Playfair Display', serif;
  line-height: 30px;
  letter-spacing: 1.5px;
}

.about > p {
  font-family: 'Volkhov', serif;
}

.about h5 {
  font-family: 'Playfair Display', serif;
}

.test {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 750px) {
  .about-contents {
    width: auto;
  }
}

.fade-appear {
  opacity: 0;
  z-index: 1;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1500ms linear;
}

h1 {
  color: white;
  font-size: 60px;
  height: 1rem;
  white-space: nowrap;
  font-weight: 00;
  letter-spacing: 2.5px;
  font-family: 'Volkhov', serif;
}

.sub-text {
  height: 10rem;
  border: 1px #282c34 solid;
}

.sub-text > div > h4 {
  font-family: 'Volkhov', serif;
  color: white;
  margin: 2rem 0;
  font-weight: 600;
}

.card-image img {
  height: 300px;
}

.card {
  width: 300px;

  background-color: #282c34 !important;
}

.card-content p {
  font-family: 'Playfair Display', serif;

  letter-spacing: 1px;
}

.card-wrapper {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 1340px) {
  .card-wrapper {
    margin-top: 11rem;
    display: flex;
    justify-content: flex-start;
  }

  .modals {
    margin-top: 40rem;
  }

  h1 {
    white-space: normal;
  }

  .title {
    margin: 4rem 0;
  }
}

@media only screen and (max-width: 440px) {
  .layout > div h1 {
    font-size: 40px;
  }
}

.skills {
  margin-top: 30rem;
  color: white;
}

.skills-contents {
  display: flex;
  flex-direction: row;
  margin: 7rem 0;
}

.skills-contents p {
  position: relative;
  left: 14rem;

  font-size: 22px;
  text-align: right;
  width: 30rem;
}

.skills-contents h5 {
  width: 14rem;
  position: relative;
  bottom: 4rem;
}

.skills-contents.front h5 {
  position: relative;
  left: 5rem;
  font-size: 30px;
  font-weight: 600;
}

.skills-contents.misc > h5 {
  position: relative;
  left: 1rem;
  font-size: 30px;
  font-weight: 600;
}

.b {
  margin-left: 2rem;
}

@media only screen and (max-width: 750px) {
  .b {
    display: flex;

    align-self: center;
  }

  .skills-contents p {
    text-align: center;
    position: inherit;
  }

  .skills-contents h5 {
    text-align: center;
    margin: 6rem 8rem 0 0;
  }

  .skills-contents.front {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .skills-contents.misc {
    display: flex;
    flex-direction: column-reverse;
  }

  .skills-contents.misc h5 {
    text-align: center;
    margin: 3em 0 0 0;
  }

  .skills-contents.misc {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}

.contact a {
  color: white;
  text-decoration: none;
  margin: 15px 0;
  width: 5rem;
  margin-bottom: 0rem;
  text-decoration: underline;
}

.contact p {
  color: white;
  font-family: 'Playfair Display', serif;
  margin-bottom: 1rem;
}

.contact {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
}

.contact > p > strong {
  font-size: 25px;
}

.ProjectContent_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.router-nav {
  background-color: white !important ;
  height: 90px !important;
  padding: 0;
}

.router-logo {
  height: 62px;
  width: 60px;
  border: black 2px solid;
  background-color: black;
  font-family: 'Volkhov', serif;
  font-size: 26px;
  border-radius: 50%;
  margin: 1rem 0 0 6rem;
  padding: 0 0 0 4px;
  color: white;
}

.brand-logo {
  width: 60px;
}

.ProjectContent_title {
  display: flex;
  align-self: center;
  justify-self: center;
}

.ProjectContent_title h3 {
  font-family: 'Volkhov', serif;
  color: black;
  height: 90px;
  margin: 35px 0 0 200px;
  font-size: 30px;
}
.ProjectContent_links {
  display: flex;
  flex-direction: column;
  width: 130vh;
  margin: 3rem 0;
}
.ProjectContent_wrapper p {
  font-family: 'Playfair Display', serif;
  letter-spacing: 1px;
}

.ProjectContent_wrapper a {
  font-family: 'Playfair Display', serif;
  letter-spacing: 1px;
  font-size: 25px;
  color: black;
}

.ProjectContent_github {
  opacity: 0.5;
  width: 150px;
}
.ProjectContent_github:hover {
  opacity: 1;
}

.ProjectContent_site {
  opacity: 0.5;
  width: 150px;
}
.ProjectContent_site:hover {
  opacity: 1;
}

.ProjectContent_gif img {
  width: 150vh;
  height: 100vh;
}

.ProjectContent_description {
  margin: 2rem 0rem;
  display: flex;
  flex-direction: row;
  align-items: initial;
}

.ProjectContent_description h6 {
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  width: 300px;
  font-size: 25px;
}

.ProjectContent_description p {
  font-weight: bold;
  width: 600px;
  margin: 0 4rem;
}

.ProjectContent_infowrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.ProjectContent_challenge {
  width: 20rem;
  display: flex;
  flex-direction: column;
}

.ProjectContent_challenge ul {
  display: flex;
  flex-direction: column;
}

.ProjectContent_challenge li {
  margin: 20px;

  display: flex;
  flex-direction: column;
}
.ProjectContent_tech {
  width: 8rem;
  margin-left: 10rem;
}
/* 
.ProjectContent_tech strong {
  width: 20rem;
} */

.ProjectContent_tech ul {
  display: flex;
  flex-direction: column;
}

.ProjectContent_tech li {
  margin: 10px 0 10px 80px;

  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 987px) {
  .ProjectContent_title h3 {
  }

  .ProjectContent_tech {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .ProjectContent_links {
    margin-left: 18rem;
  }
  .ProjectContent_description p {
    width: 400px;
  }

  .ProjectContent_gif img {
    width: 800px;
  }
}

@media only screen and (max-width: 787px) {
  .ProjectContent_gif img {
    width: 500px;
  }

  .ProjectContent_description {
    flex-direction: column;
    align-items: center;
  }

  .ProjectContent_links {
    margin-left: 40rem;
  }
  .ProjectContent_description p {
    width: 400px;
  }

  .ProjectContent_description {
    flex-direction: column;
    align-items: center;
  }

  .ProjectContent_infowrapper {
    display: flex;
    flex-direction: column !important;
  }
}

.page {
  position: absolute;
  left: 0;
  right: 0;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease 300ms;
}

