@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Volkhov:ital@0;1&display=swap');

.skills {
  margin-top: 30rem;
  color: white;
}

.skills-contents {
  display: flex;
  flex-direction: row;
  margin: 7rem 0;
}

.skills-contents p {
  position: relative;
  left: 14rem;

  font-size: 22px;
  text-align: right;
  width: 30rem;
}

.skills-contents h5 {
  width: 14rem;
  position: relative;
  bottom: 4rem;
}

.skills-contents.front h5 {
  position: relative;
  left: 5rem;
  font-size: 30px;
  font-weight: 600;
}

.skills-contents.misc > h5 {
  position: relative;
  left: 1rem;
  font-size: 30px;
  font-weight: 600;
}

.b {
  margin-left: 2rem;
}

@media only screen and (max-width: 750px) {
  .b {
    display: flex;

    align-self: center;
  }

  .skills-contents p {
    text-align: center;
    position: inherit;
  }

  .skills-contents h5 {
    text-align: center;
    margin: 6rem 8rem 0 0;
  }

  .skills-contents.front {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .skills-contents.misc {
    display: flex;
    flex-direction: column-reverse;
  }

  .skills-contents.misc h5 {
    text-align: center;
    margin: 3em 0 0 0;
  }

  .skills-contents.misc {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}
