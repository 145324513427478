@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Volkhov:ital@0;1&display=swap');

.contact a {
  color: white;
  text-decoration: none;
  margin: 15px 0;
  width: 5rem;
  margin-bottom: 0rem;
  text-decoration: underline;
}

.contact p {
  color: white;
  font-family: 'Playfair Display', serif;
  margin-bottom: 1rem;
}

.contact {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
}

.contact > p > strong {
  font-size: 25px;
}
