@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Volkhov:ital@0;1&display=swap');

.ProjectContent_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.router-nav {
  background-color: white !important ;
  height: 90px !important;
  padding: 0;
}

.router-logo {
  height: 62px;
  width: 60px;
  border: black 2px solid;
  background-color: black;
  font-family: 'Volkhov', serif;
  font-size: 26px;
  border-radius: 50%;
  margin: 1rem 0 0 6rem;
  padding: 0 0 0 4px;
  color: white;
}

.brand-logo {
  width: 60px;
}

.ProjectContent_title {
  display: flex;
  align-self: center;
  justify-self: center;
}

.ProjectContent_title h3 {
  font-family: 'Volkhov', serif;
  color: black;
  height: 90px;
  margin: 35px 0 0 200px;
  font-size: 30px;
}
.ProjectContent_links {
  display: flex;
  flex-direction: column;
  width: 130vh;
  margin: 3rem 0;
}
.ProjectContent_wrapper p {
  font-family: 'Playfair Display', serif;
  letter-spacing: 1px;
}

.ProjectContent_wrapper a {
  font-family: 'Playfair Display', serif;
  letter-spacing: 1px;
  font-size: 25px;
  color: black;
}

.ProjectContent_github {
  opacity: 0.5;
  width: 150px;
}
.ProjectContent_github:hover {
  opacity: 1;
}

.ProjectContent_site {
  opacity: 0.5;
  width: 150px;
}
.ProjectContent_site:hover {
  opacity: 1;
}

.ProjectContent_gif img {
  width: 150vh;
  height: 100vh;
}

.ProjectContent_description {
  margin: 2rem 0rem;
  display: flex;
  flex-direction: row;
  align-items: initial;
}

.ProjectContent_description h6 {
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  width: 300px;
  font-size: 25px;
}

.ProjectContent_description p {
  font-weight: bold;
  width: 600px;
  margin: 0 4rem;
}

.ProjectContent_infowrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.ProjectContent_challenge {
  width: 20rem;
  display: flex;
  flex-direction: column;
}

.ProjectContent_challenge ul {
  display: flex;
  flex-direction: column;
}

.ProjectContent_challenge li {
  margin: 20px;

  display: flex;
  flex-direction: column;
}
.ProjectContent_tech {
  width: 8rem;
  margin-left: 10rem;
}
/* 
.ProjectContent_tech strong {
  width: 20rem;
} */

.ProjectContent_tech ul {
  display: flex;
  flex-direction: column;
}

.ProjectContent_tech li {
  margin: 10px 0 10px 80px;

  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 987px) {
  .ProjectContent_title h3 {
  }

  .ProjectContent_tech {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .ProjectContent_links {
    margin-left: 18rem;
  }
  .ProjectContent_description p {
    width: 400px;
  }

  .ProjectContent_gif img {
    width: 800px;
  }
}

@media only screen and (max-width: 787px) {
  .ProjectContent_gif img {
    width: 500px;
  }

  .ProjectContent_description {
    flex-direction: column;
    align-items: center;
  }

  .ProjectContent_links {
    margin-left: 40rem;
  }
  .ProjectContent_description p {
    width: 400px;
  }

  .ProjectContent_description {
    flex-direction: column;
    align-items: center;
  }

  .ProjectContent_infowrapper {
    display: flex;
    flex-direction: column !important;
  }
}
