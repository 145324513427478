@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Volkhov:ital@0;1&display=swap');

.about > h1 {
  color: black;
  margin-bottom: 7rem;
}

.about {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  align-items: center;
}

.about-contents {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
  width: 100vh;
}

span {
  font-size: 22px;
  font-weight: 800;
}

p.flow-text {
  font-size: 20px;
  font-family: 'Playfair Display', serif;
  line-height: 30px;
  letter-spacing: 1.5px;
}

.about > p {
  font-family: 'Volkhov', serif;
}

.about h5 {
  font-family: 'Playfair Display', serif;
}

.test {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 750px) {
  .about-contents {
    width: auto;
  }
}
