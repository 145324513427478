@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi&display=swap');

html,
body {
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-repeat: no-repeat;
}

h1 h2 h3 h4 {
  font-family: 'Reem Kufi', sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  background: linear-gradient(350deg, white 25%, #282c34 0%);
  box-sizing: border-box;
  height: 170vh;
  width: 100%;
}

.nav {
  background-color: white;
}

.main {
  background: local white;
}

.footer {
  background: linear-gradient(350deg, #282c34 81%, white 0%);
  height: 100%;
}

.material-icons.a {
  border: solid black 2px;
  border-radius: 50%;
  padding: 12px;
  height: 70px;
  font-size: 40px;
  color: white;
  background-color: #282c34;
  margin-right: 25px;
}

.material-icons.b {
  border: solid white 2px;

  border-radius: 50%;
  padding: 12px;
  height: 70px;
  font-size: 40px;
  color: white;
}

.icon {
  margin-left: 1rem;
}

@media only screen and (max-width: 1340px) {
  .header {
    height: 270vh;
  }
}

@media only screen and (max-width: 400px) {
  .header {
    height: 370vh;
  }
}
